import * as React from "react"
import {StaticImage} from "gatsby-plugin-image";
import Container from "@mui/material/Container";
import {Box, Grid, Typography} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import {sectionSpacing} from "../theme";


export default () => {
  return (
    <>
      <Toolbar />
      <Container
        maxWidth={'lg'}
      >
        <Box
          marginY={sectionSpacing}
          textAlign={'center'}
        >
          <Typography
            variant={'subtitle1'}
            fontWeight={'bold'}
            textTransform={'uppercase'}
          >
            De Wachters
          </Typography>
          <Typography
            variant={'h3'}
          >
            <Box component={'span'} color={'primary.main'}>Nautisch genieten</Box> vanuit de woonkamer
          </Typography>
        </Box>
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
          >
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
            >
              <Container
                maxWidth={'sm'}
              >
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Water geeft natuurlijk ruimte
                </Typography>
                <Typography variant={'body2'} paragraph>
                  Het wonen op en rond het water biedt naast sereniteit ook de nodige levendigheid. Het water tussen de straten en langs de woningen geeft het ensemble van De Wachters een aangename natuurlijke uitstraling.
                </Typography>
                <Typography variant={'body2'} paragraph>
                  Het water is rustgevend en biedt een bron van leven. Net als de uitstraling en inhoud van de woningen zelf. De positionering van deze 18 woningen vormen in ligging en in hoogte opvallende markeringen van de hoofdvaarroute. Komend vanuit de sluis begeleiden ze de boten naar links en rechts. Het zijn echte blikvangers die zich vereenzelvigen met het nautische leven.
                </Typography>
              </Container>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/GettyImages-825901472@2x.jpg'}
                alt={'water'}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginY={sectionSpacing}>
          <StaticImage
            src={'../images/sfeer/GettyImages-942591024@2x.jpg'}
            alt={'haven'}
          />
        </Box>
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
          >
            <Grid
              item
              xs={12}
              md={6}
              display={'flex'}
            >
              <Container
                maxWidth={'sm'}
              >
                <Box>
                  <Typography
                    variant={'h6'}
                    fontWeight={'bold'}
                    color={'primary'}
                  >
                    Varen door de polder
                  </Typography>
                  <Typography variant={'body2'} paragraph>
                    Van open water naar de polder. Wie de Blauwe Diamant route volgt, voelt de rust van het varen door de natuur. Het is een heerlijke route voor jong en oud en voor ervaren en onervaren. Het is een ultiem genietmoment door de polder. Je komt bossen en rustige wateren tegen, maar ook meerdere sluizen, kortom een leuke route voor iedereen.
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant={'h6'}
                    fontWeight={'bold'}
                    color={'primary'}
                  >
                    Vrij struinen door de Stille Kern
                  </Typography>
                  <Typography variant={'body2'} paragraph>
                    En dan deze groene parel: de Stille Kern. De meest stille plek van het Horsterwold, van maar liefst 900 hectare groot. Het is hier vrij struinen. U hoort hier hooguit een kudde Konikpaarden grazen of het geritsel van damherten die zich in het hoge gras verschuilen.
                  </Typography>
                  <Typography variant={'body2'} paragraph>
                    Een wandeling of fietstocht voert u langs een verscheidenheid aan landschappen. Van open weides tot gesloten bossen. In het voorjaar ziet u de meidoornstruiken in bloei. Een prachtig gezicht.
                  </Typography>
                  <Typography variant={'body2'} paragraph>
                    Blijf even staan bij de waterplas en zie de veel soorten weidevogels die hier rondvliegen. U ziet hier de zeearend, grutto’s, zwanen en lepelaars bij elkaar. Een waar spektakel, zeker in september, de trekmaand.
                  </Typography>
                </Box>
              </Container>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/IMG_4240@2x.png'}
                alt={'water'}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginY={sectionSpacing}>
          <Box
            borderColor={'primary.main'}
            borderLeft={'2px solid'}
            color={'primary.main'}
            fontStyle={'italic'}
            fontSize={44}
          >
            <blockquote>
              Veel groen, water en vergezichten,<br /> het is wat Zeewolde onderscheidt.
            </blockquote>
          </Box>
        </Box>
        <Box
          component={'section'}
          marginY={sectionSpacing}
        >
          <Grid
            container
            order={'reverse'}
          >
            <Grid
              item
              xs={12}
              md={6}
              order={{xs: 2, md: 1}}
              display={'flex'}
            >
              <Container
                maxWidth={'sm'}
              >
                <Typography
                  variant={'h6'}
                  fontWeight={'bold'}
                  color={'primary'}
                >
                  Een uitgekiende mix van winkelen en wonen
                </Typography>
                <Typography variant={'body2'} paragraph>
                  Zeewolde kent veel voorzieningen en ontspanningsmogelijkheden. Het centrum biedt een uitgebreid aanbod van winkels voor uw dagelijkse benodigdheden. Maar ook als u ergens een hapje wilt eten of gezellig een drankje wilt doen, hoeft u niet ver te zoeken.
                </Typography>
                <Typography variant={'body2'} paragraph>
                  Uiteraard is aan overige voorzieningen ook geen gebrek, met gezondheidscentra, basis- en voortgezet onderwijs en een rijk (sport)verenigingsleven. Het is in Zeewolde gewoon heel fijn wonen.
                </Typography>
              </Container>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <StaticImage
                src={'../images/sfeer/omgeving29@2x.jpg'}
                alt={'water'}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}
